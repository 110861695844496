import { average } from "color.js";

document.addEventListener("keydown", (e) => {
  if (e.keyCode === 71 && e.ctrlKey === true) {
    document.querySelector(".grid-overlay").classList.toggle("show");
  }
});

function parents(element, className) {
  let parent = element.parentElement;
  while (parent && !parent.classList.contains(className)) {
    parent = parent.parentElement;
  }
  return parent;
}

document.addEventListener("DOMContentLoaded", () => {
  const mobileMenuLink = document.querySelector(".mobile-menu-link");
  mobileMenuLink.addEventListener("click", (e) => {
    mobileMenuLink.classList.toggle("open");
    document.querySelector("body").classList.toggle("no-scroll-m");
    document.querySelector("header").classList.toggle("mobile-menu-open");
    if (headerSearch.classList.contains("open")) {
      headerSearch.classList.remove("open");
      document.querySelector(".header-search-wrap").classList.remove("show");
    }
    e.preventDefault();
  });

  const headerSearch = document.querySelector(".header-search > a");
  headerSearch.addEventListener("click", (e) => {
    headerSearch.classList.toggle("open");
    document.querySelector(".header-search-wrap").classList.toggle("show");
    setTimeout(() => {
      document.querySelector(".header-search-wrap form input#s").focus();
    }, 300);
    if (mobileMenuLink.classList.contains("open")) {
      mobileMenuLink.classList.remove("open");
      document.querySelector("body").classList.remove("no-scroll-m");
      document.querySelector("header").classList.remove("mobile-menu-open");
    }
    e.preventDefault();
  });

  const pageOverlay = document.querySelector(".header-search-wrap .page-overlay");
  pageOverlay.addEventListener("click", (e) => {
    headerSearch.classList.remove("open");
    document.querySelector(".header-search-wrap").classList.remove("show");
    e.preventDefault();
  });

  const cartButton = document.querySelector(".cart-link");
  cartButton.addEventListener("click", (e) => {
    cartButton.classList.toggle("open");
    document.querySelector("body").classList.toggle("no-scroll");
    document.querySelector(".cart-overlay").classList.add("show");
    e.preventDefault();
  });

  const cartOverlay = document.querySelector(".cart-overlay");
  if (cartOverlay) {
    cartOverlay.addEventListener("click", (e) => {
      if (!e.target.classList.contains("cart-drawer") && !parents(e.target, "cart-drawer")) {
        cartButton.classList.toggle("open");
        document.querySelector("body").classList.remove("no-scroll");
        document.querySelector(".cart-overlay").classList.remove("show");
        e.preventDefault();
      }
    });
  }

  const closeCartOverlay = document.querySelector(".close-cart-overlay");
  if (closeCartOverlay) {
    closeCartOverlay.addEventListener("click", (e) => {
      cartButton.classList.remove("open");
      document.querySelector("body").classList.remove("no-scroll");
      document.querySelector(".cart-overlay").classList.remove("show");
      e.preventDefault();
    });
  }

  document.addEventListener("keyup", (e) => {
    if (e.keyCode === 27) {
      if (headerSearch.classList.contains("open")) {
        headerSearch.classList.remove("open");
        document.querySelector(".header-search-wrap").classList.remove("show");
      }
      if (cartButton.classList.contains("open")) {
        cartButton.classList.remove("open");
        document.querySelector("body").classList.remove("no-scroll");
        document.querySelector(".cart-overlay").classList.remove("show");
      }
      closeAllSelect();
    }
  });

  const editCollection = document.querySelector(".edit-collection");
  if (editCollection) {
    editCollection.addEventListener("click", (e) => {
      document.querySelector(".edit-collection-sidebar").classList.add("show");
      e.preventDefault();
    });
  }
  const createCollections = document.querySelectorAll(".create-collection");
  if (createCollections) {
    createCollections.forEach((createCollection) => {
      createCollection.addEventListener("click", (e) => {
        document.querySelector(".edit-collection-sidebar").classList.add("show");
        e.preventDefault();
      });
    });
  }
  const editCollectionPageOverlay = document.querySelector(".edit-collection-sidebar .page-overlay");
  if (editCollectionPageOverlay) {
    editCollectionPageOverlay.addEventListener("click", (e) => {
      document.querySelector(".edit-collection-sidebar").classList.remove("show");
      e.preventDefault();
    });
  }
  const closeEditCollection = document.querySelector(".close-edit-collection-sidebar");
  if (closeEditCollection) {
    closeEditCollection.addEventListener("click", (e) => {
      document.querySelector(".edit-collection-sidebar").classList.remove("show");
      e.preventDefault();
    });
  }

  const addToCollections = document.querySelectorAll(".add-to-collection");
  if (addToCollections) {
    addToCollections.forEach((addToCollection) => {
      addToCollection.addEventListener("click", (e) => {
        const product = parents(addToCollection, "product-grid-block");
        addToCollection.classList.toggle("active");
        product.querySelector(".add-to-collection-dialog").classList.toggle("show");
        e.preventDefault();
      });
    });
  }

  const customMultipleDropdowns = document.querySelectorAll(".custom-multiple-dropdown");
  if (customMultipleDropdowns) {
    customMultipleDropdowns.forEach((customMultipleDropdown) => {
      customMultipleDropdown.querySelector("a.toggle").addEventListener("click", (e) => {
        customMultipleDropdown.classList.toggle("open");
        e.preventDefault();
      });
    });
  }

  const productImageSliders = document.querySelectorAll(".product-image-slider");
  productImageSliders.forEach((productImageSlider) => {
    const firstImage = productImageSlider.querySelector(".slide:first-child img");
    average(firstImage, { amount: 1 }).then((color) => {
      // console.log(color);
      productImageSlider.style.backgroundColor = "rgba(" + color[0] + ", " + color[1] + ", " + color[2] + ", .1)";
    });

    let options = {
      cellAlign: "left",
      contain: true,
      setGallerySize: false,
      wrapAround: true,
      prevNextButtons: false,
      selectedAttraction: 0.2,
      friction: 0.8,
      autoPlay: 4000,
      pauseAutoPlayOnHover: false,
    };

    let flkty = new Flickity(productImageSlider, options);

    productImageSlider.addEventListener("pointerup", () => {
      flkty.playPlayer();
    });
  });

  const tabNavLinks = document.querySelectorAll(".tabs-nav a");
  tabNavLinks.forEach((tabNavLink) => {
    tabNavLink.addEventListener("click", (e) => {
      const clicked = e.target;
      const nav = parents(clicked, "tabs-nav");
      const tabs = parents(clicked, "tabs-wrap").querySelector(".tabs");
      const newTabID = clicked.getAttribute("data-tab");
      const newTab = tabs.querySelector('.tab[data-tab="' + newTabID + '"]');
      if (!clicked.classList.contains("active")) {
        const otherLinks = nav.querySelectorAll("a");
        otherLinks.forEach((otherLink) => {
          otherLink.classList.remove("active");
        });
        clicked.classList.add("active");
        const otherTabs = tabs.querySelectorAll(".tab");
        otherTabs.forEach((otherTab) => {
          otherTab.classList.remove("active");
        });
        newTab.classList.add("active");
        window.location.hash = newTabID;
      }
      e.preventDefault();
    });
  });

  const productsSliders = document.querySelectorAll(".products-slider");
  productsSliders.forEach((productsSlider) => {
    const duration = parseInt(productsSlider.style.getPropertyValue("--delay")) * 1000;
    let options = {
      cellAlign: "left",
      contain: true,
      wrapAround: true,
      prevNextButtons: false,
      pauseAutoPlayOnHover: false,
      autoPlay: duration,
      groupCells: true,
    };

    let flkty = new Flickity(productsSlider, options);

    productsSlider.addEventListener("pointerup", () => {
      flkty.playPlayer();
    });
  });

  const sortSelects = document.querySelectorAll(".sort-dropdown select");
  sortSelects.forEach((sortSelect) => {
    const wrap = parents(sortSelect, "sort-dropdown");
    sortSelect.addEventListener("change", () => {
      sortSelect.blur();
    });
    sortSelect.addEventListener("focus", () => {
      wrap.classList.add("open");
    });
    sortSelect.addEventListener("blur", () => {
      wrap.classList.remove("open");
    });
    sortSelect.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        wrap.classList.remove("open");
      }
    });
  });

  const updatePreviewText = (selector, string) => {
    const previewTexts = document.querySelectorAll(selector);
    previewTexts.forEach((previewText) => {
      if (string.trim().length !== 0) {
        previewText.innerHTML = string;
      }
    });
  };

  const fontPreviewTool = document.querySelector(".font-preview-tool input");
  if (fontPreviewTool) {
    fontPreviewTool.addEventListener("input", () => {
      const content = fontPreviewTool.value;
      const start = parents(fontPreviewTool, "font-preview-tool").getAttribute("data-start");
      const target = parents(fontPreviewTool, "font-preview-tool").getAttribute("data-target");
      if (content === "") {
        updatePreviewText(target, start);
      } else {
        updatePreviewText(target, content);
      }
    });
  }

  const fontSizeInputs = document.querySelectorAll(".font-size-tool input[type=range]");
  fontSizeInputs.forEach((fontSizeInput) => {
    fontSizeInput.addEventListener("input", () => {
      const newSize = fontSizeInput.value;
      const tool = parents(fontSizeInput, "font-tool");
      const main = parents(fontSizeInput, "with-font-tools");
      main.style.setProperty("--font-size", newSize + "px");
      tool.querySelector("span.value").innerHTML = newSize;
    });
  });

  const letterSpacingTools = document.querySelectorAll(".letter-spacing-tool input[type=range]");
  letterSpacingTools.forEach((letterSpacingTool) => {
    letterSpacingTool.addEventListener("input", () => {
      const newLetterSpacing = letterSpacingTool.value;
      const tool = parents(letterSpacingTool, "font-tool");
      const main = parents(letterSpacingTool, "with-font-tools");
      main.style.setProperty("--letter-spacing", newLetterSpacing / 1000 + "em");
      tool.querySelector("span.value").innerHTML = newLetterSpacing;
    });
  });

  const fontFeaturesTools = document.querySelectorAll(".font-features-tool input[name=fontfeature]");
  fontFeaturesTools.forEach((fontFeaturesTool) => {
    fontFeaturesTool.addEventListener("input", () => {
      const tool = parents(fontFeaturesTool, "font-tool");
      const main = parents(fontFeaturesTool, "with-font-tools");
      let otFeatureList = [];
      fontFeaturesTools.forEach((fontFeaturesTool) => {
        if (fontFeaturesTool.checked) {
          let featureStyle = fontFeaturesTool.value;
          otFeatureList.push(featureStyle);
        }
      });
      main.style.setProperty("--font-features", otFeatureList.toString());
    });
  });

  var x, i, j, l, ll, selElmnt, a, b, c;
  x = document.getElementsByClassName("custom-dropdown");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    let icon = selElmnt.options[selElmnt.selectedIndex].getAttribute("data-icon");
    if (icon) {
      a.innerHTML = '<svg class="icon icon-' + icon + '"><use xlink:href="#icon-' + icon + '"></use></svg>' + selElmnt.options[selElmnt.selectedIndex].innerHTML;
    } else {
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    }
    x[i].appendChild(a);
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 0; j < ll; j++) {
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      if (j === 0) {
        c.classList.add("same-as-selected");
        if (selElmnt.options[j].getAttribute("disabled") !== null) {
          c.classList.add("disabled");
        }
      }
      c.addEventListener("click", function (e) {
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            if (icon) {
              h.innerHTML = '<svg class="icon icon-' + icon + '"><use xlink:href="#icon-' + icon + '"></use></svg>' + this.innerHTML;
            } else {
              h.innerHTML = this.innerHTML;
            }
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].classList.remove("same-as-selected");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
        h.classList.add("selected");
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  document.addEventListener("click", closeAllSelect);

  const glyphsetCells = document.querySelectorAll(".glyphset-cell");
  glyphsetCells.forEach((glyphsetCell) => {
    const glyphsetPreview = parents(glyphsetCell, "glyphs-wrap").querySelector(".glyph-preview");
    const previewCell = () => {
      const newPreview = glyphsetCell.innerHTML;
      glyphsetPreview.innerHTML = newPreview;
    };
    glyphsetCell.addEventListener("mouseenter", previewCell);
    glyphsetCell.addEventListener("pointerdown", previewCell);
  });

  const readMoreLinks = document.querySelectorAll(".read-more .read-more-link");
  readMoreLinks.forEach((readMoreLink) => {
    readMoreLink.addEventListener("click", (e) => {
      const readMore = parents(readMoreLink, "read-more");
      if (readMoreLink.classList.contains("active")) {
        readMore.classList.remove("open");
        readMoreLink.innerText = "Read more";
        readMoreLink.classList.remove("active");
      } else {
        readMore.classList.add("open");
        readMoreLink.innerText = "Read less";
        readMoreLink.classList.add("active");
      }
      e.preventDefault();
    });
  });
});

let tempCount = 1;
window.addEventListener("scroll", function () {
  tempCount = 1;
});
let productObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("has-been-seen");
        entry.target.classList.add("active");
        entry.target.style.setProperty("--i", tempCount);
        setTimeout(function () {
          entry.target.classList.add("animation-complete");
        }, tempCount * 80 + 1000);
        tempCount++;
      } else {
        entry.target.classList.remove("active");
      }
    });
  },
  { rootMargin: "0px 0px 0px 0px" }
);
const products = document.querySelectorAll(".appear");
products.forEach((col) => {
  productObserver.observe(col);
});
